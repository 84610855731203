import React from 'react'
import { Input } from '@chakra-ui/react'

import { FormControlWrapper } from '../FormControlWrapper/FormControlWrapper'

type InputProps = React.ComponentProps<typeof Input>

type Props = InputProps & {
  label: string
  name: string
  required?: boolean
  min?: string
  max?: string
}

export const InputWithLabel: React.FC<Props> = (props) => {
  const { label, name, required, type, min, max, ...p } = props
  return (
    <FormControlWrapper label={label} name={name} required={required}>
      <Input
        {...p}
        id={name}
        max={max}
        min={min}
        name={name}
        type={type ?? 'text'}
      />
    </FormControlWrapper>
  )
}
